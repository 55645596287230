import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Components.module.scss';
type FooterProps = { leftContent?: ReactNode; rightContent?: ReactNode };
const Footer = ({ leftContent, rightContent }: FooterProps) => {
  const nav = useNavigate();
  return (
    <div className={styles.footer}>
      {leftContent ?? (
        <div className={styles.footerBack} onClick={() => nav('/pay')}>
          <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
          <p>Tillbaka</p>
        </div>
      )}
      {rightContent}
    </div>
  );
};
export default Footer;
