import { CSSProperties, ReactNode, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMainContext } from '../context/MainContext';

import { BlockSpinner } from '../utils/HelperComponents';
import { signInWithCustomToken } from 'firebase/auth';
import { ArticleListing, verifyAccount } from '../api/quiqly-api';
import styles from './Components.module.scss';
import { apps } from '../context/Singletons';


// FIXME: Here we should also show which articles were now credited to the account. Perhaps even link them. Perhaps even with auto-unlock link.
// FIXME: Bundle login token such that user is auto logged in.
export const Verify = () => {
  const { verificationCode } = useParams();
  const { user } = useMainContext();
  const [message, setMessage] = useState<string>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error>();
  const [, setArticleListing] = useState<ArticleListing[]>();
  console.log('USER IS', user);
  useEffect(() => {
    if (!verificationCode) {
      setMessage('No verification code provided');
      setLoading(false);
    } else {
      verifyAccount({ verificationCode })
        .then(({ articles: articleListing, loginToken }) => {
          setMessage('Tack för att du verifierat din mejladress');
          signInWithCustomToken(apps.auth.main, loginToken).then((rec) => console.log(`Logged in as ${rec.user.email}`));
          setArticleListing(articleListing);
          setLoading(false);
        })
        .catch((e) => {
          setError(e);
          setLoading(false);
        });
    }
  }, [verificationCode]);
  return loading ? (
    <BlockSpinner />
  ) : (<><div></div><div className={styles.verify}>
      <h1>Quiqly</h1>
      {error?.message && <SmallBold>{error.message}</SmallBold>}
      {message && <SmallBold>{message}</SmallBold>}
    </div><div></div></>);
};

const SmallBold = ({ children, style = {} }: { children: ReactNode; style?: CSSProperties }) => (
  <span style={{ fontSize: 12, fontWeight: 'bold', ...style }}>{children}</span>
);

/*
{articleListing && articleListing.length > 0 && <p>You can now access:</p>}
      {articleListing?.map((al) => (
        <a href={al.articleUrl}>{al.articleTitle}</a>
      ))}
*/