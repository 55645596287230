import React, { useEffect, useState } from 'react';
import { BlockSpinner } from '../utils/HelperComponents';
import styles from './MainContext.module.scss';
import Header from '../components/Header';
import { useAnonUser, useMainUser, UserWithHandler } from './Hooks';
import { OTPLogin } from '../api/quiqly-api';
import { getWindowAncestor } from '../utils/HelperFunctions';

type MainCtxType = {
  user: UserWithHandler | null;
  anon: UserWithHandler;
  params: {
    otp?: string,
    resourceId: string,
    page?: string,
    partner?: string,
    init?: 0 | 1
  },
  mode: "modal" | "popup"
};

const {otp, page, resourceId, ignoreAncestorContext, partner, init, context: _context} = JSON.parse(new URLSearchParams(new URL(window.location.href).search).get('quiqly') || '{}')
let context : "popup" | "modal" = _context
if(!context) {
  context = window.opener ? "popup" : "modal";
}
console.log("[QUIQLY] Mode:", context);

if(context) {
  document.body.parentElement?.classList.add(`quiqly-mode-${context}`);
}
// FIXME - page will not work yet

// If not iframe, load the mother page with appropriate quiqly-params instead FIXME
//FIXME use similar check when messaging home
const ancestorWindow = getWindowAncestor();
if(ancestorWindow === window && resourceId && !(init === 1)) {
  const newUrl = new URL(resourceId);
  const newUrlSearchParams = new URLSearchParams(newUrl.search);
  newUrlSearchParams.set('quiqly', JSON.stringify({open: true, otp: otp, page: page}));
  newUrl.search = newUrlSearchParams.toString();
  
  (window as any).location = newUrl;
}

const initialCtx: MainCtxType = {
  user: null as UserWithHandler | null,
  anon: (null as unknown) as UserWithHandler,
  params: {
    otp,
    resourceId,
    page,
    partner,
    init
  },
  mode: context
};
const MainCtx = React.createContext<MainCtxType>(initialCtx);
const MainContext = ({ children }: { children: any }) => {
  const [otpData, setOtpData] = useState<{token: string, anon: boolean}|false|undefined>();
  const anon = useAnonUser(otpData); // FIXME having null and undefined isn't great. Add a real auth state, idtoken state included. (no user, user-notoken, user-token, uninitialized)
  const user = useMainUser(otpData);

  useEffect(() => {
      console.log("HAS OTP?", otp !== undefined);
      if(otp) {
          console.log("Exchanging otp ", otp);
          OTPLogin(otp).then(({token, anon}) => {
              console.log('GOT OTP EXCHANGE')
              setOtpData({token, anon});
          });
      }else{
        console.log("NO OTP")
        setOtpData(false);
      }
  }, []);

  
  // We require an anon user, and that main is checked before we proceed
  const anonLoaded = anon !== null && anon !== undefined;
  const mainLoaded = user !== undefined;
  //const hasArticleUrl = params.articleUrl !== undefined;
  const shouldProceedWithRender = anonLoaded && mainLoaded;// && hasArticleUrl;
  if(!shouldProceedWithRender) {
    return <div className={`${styles.mainContainer} quiqly-mainContext`}>
        <div className={`${styles.innerContainer} quiqly-innerContext`}>
          <Header />
          <BlockSpinner text="Laddar applikationen"/>
          <div></div>
        </div>
      </div>
  }

  return (
    <MainCtx.Provider
      value={{
        user,
        anon,
        params: {otp, page, resourceId, partner},
        mode: context
      }}
    >
      <div className={`${styles.mainContainer} quiqly-mainContext`}>
        <div className={`${styles.innerContainer} quiqly-innerContext`}>{children}<div></div></div>
      </div>
    </MainCtx.Provider>
  );
};

export default MainContext;
export const useMainContext = () => React.useContext(MainCtx);
