import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import MainContext from './context/MainContext';
import { BrowserRouter } from 'react-router-dom';
import './context/Singletons';
import './index.css';

/*
(window as any).ApplePaySession = new Proxy((window as any).ApplePaySession, {
  get(target, p, receiver) {
    console.log('[AP] Get', target, p, receiver);
    return Reflect.get(target, p, receiver)
  },
  apply(target, thisArg, argumentsList) {
    console.log('[AP] Apply', target, thisArg, argumentsList);
    return Reflect.apply(target, thisArg, argumentsList)
  },
  construct(target, args) {
    console.log('[AP] Construct', target, args);
    return Reflect.construct(target, args);
  }
});

(window as any).ApplePayError = new Proxy((window as any).ApplePayError, {
  get(target, p, receiver) {
    console.log('[AP] Get', target, p, receiver);
    return Reflect.get(target, p, receiver)
  },
  apply(target, thisArg, argumentsList) {
    console.log('[AP] Apply', target, thisArg, argumentsList);
    return Reflect.apply(target, thisArg, argumentsList)
  },
  construct(target, args) {
    console.log('[AP] Construct', target, args);
    return Reflect.construct(target, args);
  }
});

(window as any).ApplePaySetup = new Proxy((window as any).ApplePaySetup, {
  get(target, p, receiver) {
    console.log('[AP] Get', target, p, receiver);
    return Reflect.get(target, p, receiver)
  },
  apply(target, thisArg, argumentsList) {
    console.log('[AP] Apply', target, thisArg, argumentsList);
    return Reflect.apply(target, thisArg, argumentsList)
  },
  construct(target, args) {
    console.log('[AP] Construct', target, args);
    return Reflect.construct(target, args);
  }
});

(window as any).ApplePaySetupFeature = new Proxy((window as any).ApplePaySetupFeature, {
  get(target, p, receiver) {
    console.log('[AP] Get', target, p, receiver);
    return Reflect.get(target, p, receiver)
  },
  apply(target, thisArg, argumentsList) {
    console.log('[AP] Apply', target, thisArg, argumentsList);
    return Reflect.apply(target, thisArg, argumentsList)
  },
  construct(target, args) {
    console.log('[AP] Construct', target, args);
    return Reflect.construct(target, args);
  }
});

(window as any).PaymentRequest = new Proxy((window as any).PaymentRequest, {
  get(target, p, receiver) {
    console.log('[AP] Get', target, p, receiver);
    return Reflect.get(target, p, receiver)
  },
  apply(target, thisArg, argumentsList) {
    console.log('[AP] Apply', target, thisArg, argumentsList);
    return Reflect.apply(target, thisArg, argumentsList)
  },
  construct(target, args) {
    console.log('[AP] Construct', target, args);
    return Reflect.construct(target, args);
  }
});
(window as any).PaymentResponse = new Proxy((window as any).PaymentResponse, {
  get(target, p, receiver) {
    console.log('[AP] Get', target, p, receiver);
    return Reflect.get(target, p, receiver)
  },
  apply(target, thisArg, argumentsList) {
    console.log('[AP] Apply', target, thisArg, argumentsList);
    return Reflect.apply(target, thisArg, argumentsList)
  },
  construct(target, args) {
    console.log('[AP] Construct', target, args);
    return Reflect.construct(target, args);
  }
});
(window as any).PaymentRequestUpdateEvent = new Proxy((window as any).PaymentRequestUpdateEvent, {
  get(target, p, receiver) {
    console.log('[AP] Get', target, p, receiver);
    return Reflect.get(target, p, receiver)
  },
  apply(target, thisArg, argumentsList) {
    console.log('[AP] Apply', target, thisArg, argumentsList);
    return Reflect.apply(target, thisArg, argumentsList)
  },
  construct(target, args) {
    console.log('[AP] Construct', target, args);
    return Reflect.construct(target, args);
  }
});
(window as any).PaymentMethodChangeEvent = new Proxy((window as any).PaymentMethodChangeEvent, {
  get(target, p, receiver) {
    console.log('[AP] Get', target, p, receiver);
    return Reflect.get(target, p, receiver)
  },
  apply(target, thisArg, argumentsList) {
    console.log('[AP] Apply', target, thisArg, argumentsList);
    return Reflect.apply(target, thisArg, argumentsList)
  },
  construct(target, args) {
    console.log('[AP] Construct', target, args);
    return Reflect.construct(target, args);
  }
});
(window as any).PaymentAddress = new Proxy((window as any).PaymentAddress, {
  get(target, p, receiver) {
    console.log('[AP] Get', target, p, receiver);
    return Reflect.get(target, p, receiver)
  },
  apply(target, thisArg, argumentsList) {
    console.log('[AP] Apply', target, thisArg, argumentsList);
    return Reflect.apply(target, thisArg, argumentsList)
  },
  construct(target, args) {
    console.log('[AP] Construct', target, args);
    return Reflect.construct(target, args);
  }
});
window.addEventListener("message", (ev) => {
  console.log('SWMSG', ev)
});
window.postMessage = new Proxy(window.postMessage, {
  get(target, p, receiver) {
    console.log('[SWMSG OUT] Get', target, p, receiver);
    return Reflect.get(target, p, receiver)
  },
  apply(target, thisArg, argumentsList) {
    console.log('[SWMSG OUT] Get', target, thisArg, argumentsList);
    return Reflect.apply(target, thisArg, argumentsList)
  },
  construct(target, args) {
    console.log('[SWMSG OUT] Get', target, args);
    return Reflect.construct(target, args);
  }
});*/

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <MainContext>
        <App />
      </MainContext>
    </BrowserRouter>
  </React.StrictMode>
);
