import styles from './Components.module.scss';
import Footer from './Footer';
import { ReactNode } from 'react';
import { Varsetter } from './Varsetter';
type ThankyouProps = { titleText?: ReactNode; contentText?: ReactNode;  onClose: () => void };
const Thankyou = ({ titleText, contentText, onClose }: ThankyouProps) => {
  /*useEffect(() => {
    const timeout = setTimeout(onClose, 6000);
    const clear = () => clearTimeout(timeout);
    return clear;
  }, [onClose]);*/
  return (
    <>
      <div className={styles.componentContainer}>
        <div className={styles.headlineTitle}>
          {titleText ?? <h2>Tack!</h2>}
          {contentText}
        </div>
        <button className={styles.submitButton} style={{background: "white", color: "black"}} onClick={() => onClose()}>
          Stäng och läs artikel
        </button>
        <p className={styles.extraInfoText}>
          <a href='https://quiqly.se' target={'_blank'} rel='noreferrer' style={{fontSize: "0.75em"}}>
            Gå till Quiqly
          </a>
        </p>
      </div>
      <Varsetter bgImage="none" bgColor="#185ADB" fontColorStrong="white" fontColorWeak="white" />
      <style>
        {`
        .quiqly-mode-popup {
          background: var(--bg-color) !important;
        }
        `}
      </style>
      <Footer leftContent={false}/>
    </>
  );
};

export default Thankyou;
