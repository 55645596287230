import { useLayoutEffect } from 'react';
import { useMainContext } from '../context/MainContext';
import styles from './Components.module.scss';

interface PurchaseProps {
  headline?: string;
  price?: string;
  name?: string;
  onChangePaymentMethod: () => void;
  includeChangePaymentLink: boolean;
  paymentUrl: string | undefined | null;
  onPaid: ({ redirectUrl, }: { redirectUrl: string }) => Promise<void>,
}

const Purchase = ({ headline, price, name, onChangePaymentMethod, includeChangePaymentLink, paymentUrl, onPaid }: PurchaseProps) => {
  const { user } = useMainContext();
  const userName = name ? name : user && user.displayName ? user.displayName.split(' ')[0] : undefined;

  useLayoutEffect(()=> {
    if(!paymentUrl || !onPaid) return;
    document.querySelector('#checkout-container > iframe')?.remove();
    
    const script = document.createElement('script');
    script.setAttribute('src', paymentUrl);
    script.onload = () => {
      (window as any).payex.hostedView
          .checkout({
            container: {
              checkout: 'checkout-container'
            },
            culture: 'sv-SE',
            onEventNotification: console.log,
            onError: (...args: any[]) => {
              console.log(args); //FIXME
            },
            onAborted: (...args: any[]) => {
              console.log(args); //FIXME
            },
            onInstrumentSelected: console.log,
            onOutOfViewOpen: console.log,
            onPaid,
            onTermsOfServiceRequested: () => window.open("https://www.quiqly.se/anvandarvillkor/")
          })
          .open();
    }
    document.getElementsByTagName('head')[0]?.appendChild(script);
  }, [onPaid, paymentUrl]);

  if(!paymentUrl)
    return <></>

  return (
    <>
      <div className={styles.componentContainer}>
        <div className={styles.headlineTitle}>
          {userName && <h3>Hej {userName}!</h3>}
          {headline && <h2>{headline}</h2>}
          {price && <p style={{fontSize: "1.5em"}}>{price}</p>}
        </div>
      </div>
      <div id='checkout-container-container'>
        <div id='checkout-container'></div>
      </div>
      {includeChangePaymentLink && (
          <p style={{ textDecoration: 'underline', color: 'grey', fontSize: 'small', textAlign: 'center', cursor: 'pointer' }} onClick={onChangePaymentMethod}>
            Byt betalningsmetod
          </p>
        )}
    </>
  );
};

export default Purchase;
