import { User } from "firebase/auth";

export const validateEmail = (email: string) => {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
};

export const isAnonymousUser = (user: User) => {
  return user.providerData.length === 0;
}

export const hasThirdPartyProvider = (user: User) => {
  return user.providerData.find(p => p.providerId !== 'password');
}

export const isNotDisabled = (user: User) => {
  return hasThirdPartyProvider(user) || user.emailVerified
}

export const getWindowAncestor = () => {
  if(window.opener !== null) {
    console.log('[QUIQLY] Found opener window, in mode: window')
    return window.opener;
  
  }else if(window.parent !== window){
    console.log('[QUIQLY] Found opener window, in mode: modal')
    return window.parent;
  }
  return window;
}