export type BasedSessionFetch = (urlFragment: string, init?: RequestInit | undefined) => Promise<Response>;
export function createBasedSessionFetch(baseUrl: string) {
  baseUrl = baseUrl.endsWith('/') ? baseUrl.slice(0, -1) : baseUrl;

  /*
   *    Return a fetch function with set baseUrl
   *    Syntax slightly different from fetch, less permissisve
   */
  return function efetch(urlFragment: string, init: RequestInit | undefined = { credentials: 'include' }) {
    const normalizedFragment = urlFragment.startsWith('/') ? urlFragment : `/${urlFragment}`;
    console.log(`Nav ${baseUrl}${normalizedFragment}`)
    return fetch(`${baseUrl}${normalizedFragment}`, { ...init, credentials: 'include' });
  };
}

export const isPopup = () => window.opener !== null