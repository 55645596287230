import { useState } from 'react';
import styles from './Components.module.scss';
import { BlockSpinner } from '../utils/HelperComponents';
import { Link } from 'react-router-dom';

interface RegisterProps {
  onResetEmailSent?: () => void
}
const ResetPasword = ({ onResetEmailSent }: RegisterProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [emailValid, setEmailValid] = useState(false);
  const [email, setEmail] = useState('');
  const [error, setError] = useState<Error>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState<boolean>();

  return (
      <div className={styles.componentContainer}>
        <div className={styles.headlineTitle}>
          <h2>Återställ lösenord</h2>
          {error?.message && <p style={{ fontWeight: 'bold', color: 'red' }}>{error.message}</p>}
        </div>
        {!loading && success === undefined && <>
            <div className={styles.inputsWrapper}>
              <input
                  type='email'
                  id='emailinput'
                  aria-describedby='emailHelp'
                  placeholder='Email'
                  style={{
                  border: '1px solid black',
                  borderRadius: '5px'
                  }}
                  pattern='^\S+@\S+\.\S+$'
                  value={email}
                  onChange={(e) => {
                    setEmailValid(e.target.checkValidity());
                    setEmail(e.target.value);
                  }}
              />
            </div>
            <button
            type='button'
            className={styles.submitButton}
            onClick={(e) => {
                e.preventDefault();
                setLoading(true);
                fetch(`${process.env.REACT_APP_QUIQLY_API_URL}/reset-password?email=${email}`).then((res) => {
                    setLoading(false);
                    setSuccess(true);
                    if(res.status !== 200) {
                      throw new Error('Bad status');
                    }
                }).catch((e) => {
                    setLoading(false);
                    setSuccess(false);
                    setError(e);
                })
            }}
            >
            Skicka återställningsmail
            </button>
        </>}
        {loading && <BlockSpinner />}
        {success === false && <p>Någonting gick fel</p>}
        {success === true && <p>Email skickat! <Link to="/login">Klicka här för att logga in</Link></p>}
      </div>
  );
};

export default ResetPasword;
