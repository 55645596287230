import {
  Auth,
  createUserWithEmailAndPassword,
  FacebookAuthProvider,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
  TwitterAuthProvider
} from 'firebase/auth';
import { validateEmail } from './HelperFunctions';

const signInWithProvider = (provider: TwitterAuthProvider | GoogleAuthProvider | FacebookAuthProvider) => async (auth: Auth) => {
  if (!auth) {
    console.log('Something went wrong with firebase auth!');
    return;
  }
  try {
    const result = await signInWithPopup(auth, provider);
    const user = result.user;
    console.log('✅ loginWithTwitter user:', user);
    const credential = FacebookAuthProvider.credentialFromResult(result);
    console.log('✅ loginWithTwitter credential:', credential);
    if (credential) {
      const accessToken = credential.accessToken;
      console.log('✅ loginWithTwitter accessToken:', accessToken);
    }
  } catch (error: any) {
    const errorCode = error.code;
    const errorMessage = error.message;
    const email = error.customData.email;
    const credential = FacebookAuthProvider.credentialFromError(error);
    console.log('🚫 loginWithTwitter failed:', errorCode, errorMessage, email, credential);
    console.log('Throwing')
    throw error
  }
}
export const signUpWithFacebook = signInWithProvider(new FacebookAuthProvider())
export const signUpWithGoogle = signInWithProvider(new GoogleAuthProvider())
export const signUpWithTwitter = signInWithProvider(new TwitterAuthProvider())
export const signUpWithEmailAndPassword = async (email: string, password: string, auth: Auth) => {
  if (!validateEmail(email)) {
    throw new Error('Email invalid');
  }
  if (!auth) {
    throw new Error('something wrong with firebase Auth!');
  }
  const userCredentials = await createUserWithEmailAndPassword(auth, email, password);
  console.info('Created account for:', userCredentials);
  if (!userCredentials.user.email || userCredentials.user.email !== email) {
    throw new Error('Email in registered user does not match input');
  }

  return userCredentials;
};

export const loginWithEmailAndPassword = async (email: string, password: string, auth: Auth) => {
  if (!validateEmail(email)) {
    throw new Error('Email invalid');
  }
  if (password.trim().length === 0) {
    throw new Error('passwords does not match');
  }
  if (!auth) {
    throw new Error('something wrong with firebase Auth!');
  }
  await signInWithEmailAndPassword(auth, email, password);
};
