import { User } from 'firebase/auth';
import { useEffect, useRef, useState } from 'react';
import { closeQuiqly, sendResources } from '../api/cross-frame';
import { ClientFacingPayment, Resource } from '../api/quiqly-api';
import { useMainContext } from '../context/MainContext';
import { sessionId } from '../utils/GlobalSessionId';
import { BlockSpinner } from '../utils/HelperComponents';
import { isAnonymousUser, isNotDisabled } from '../utils/HelperFunctions';
import FatalError from './FatalError';
import Footer from './Footer';
import Header from './Header';
import { NagConversion } from './NagConversion';
import Purchase from './Purchase';
import { RegLogin } from './RegisterOrLogin';
import Thankyou from './Thankyou';

enum Phase {
  IDLE = 0,
  START = 1,
  INITIALIZING = 2, 
  INITIALIZED = 3,
  CONVERSION_OFFER = 4,
  PAYING = 5,
  PAID = 6,
  NAG_REGISTER = 7,
  THANK_YOU = 8,
  CHANGING_USER = 9,
}

const Payment2 = () => {
  const { user, anon, params } = useMainContext();
  const [settings, setSettings] = useState({token: true, lookup: true, create: true});
  const [currentUser, setCurrentUser] = useState(user ?? anon); 
  const [returningCustomer, setReturningCustomer] = useState(false);
  const [error, setError] = useState<Error>();
  const [sentError, setSentError] = useState<boolean>(false);
  const [phase, setPhase] = useState<Phase>(Phase.START);
  const refPhase = useRef(phase);


  useEffect(() => {
    if(error !== undefined) {
      currentUser.handler.sendEvent({
        name: "error",
        resourceId: params.resourceId,
        params: {
          error: error.message,
          trace: error.stack
        }
      });
      setSentError(true);
    }
  }, [error, currentUser, params.resourceId])

  useEffect(() => {
    console.log("Phase", phase, "refphase", refPhase.current)
    if(refPhase.current < phase) {
      currentUser.handler.sendEvent({
        name: `phase-${phase}`,
        resourceId: params.resourceId,
      });
      refPhase.current = phase;
    }
    if(phase === Phase.START && refPhase.current !== Phase.START) {
      refPhase.current = phase;
      currentUser.handler.sendEvent({
        name: `phase-${phase}`,
        resourceId: params.resourceId
      });
    }

  }, [refPhase, phase, currentUser, params])


  useEffect(() => {
    if(phase === Phase.CHANGING_USER) return; //Already on the move
    if(currentUser === anon && !!user) {
      setPhase(Phase.CHANGING_USER);
      if(anon.handler.getPayment() === null){
        setCurrentUser(user);
        setPhase(Phase.START);
        return;
      }

      // FIXME - could handle unverified here
      anon.handler.transferTo(user.handler).then(() => {
        setCurrentUser(user);
        setPhase(Phase.INITIALIZED);
      }).catch((error: Error) => {
        setError(error);
      });
    }
  }, [currentUser, anon, user, phase])

  useEffect(() => {
    if(phase !== Phase.START) return;
    setPhase(Phase.INITIALIZING);

    currentUser.handler
      .initialize({settings, resourceId: params.resourceId})
      .then(() => setPhase(Phase.INITIALIZED))
      .catch((e: Error) => {
        setError(e)
      });
  }, [currentUser, phase, settings, params.resourceId]);

  useEffect(() => {
    if(phase !== Phase.INITIALIZED) return;
    const payment = currentUser.handler.getPayment();
    const offer = currentUser.handler.getConversionOffer();
    if(!payment) {
      console.log("No payment present, reinitializing");
      return setPhase(Phase.START);
    }else{
      if(currentUser.handler.isPaid()) {
        setPhase(Phase.PAID);
        setReturningCustomer(true);
      }else if(offer){
        setPhase(Phase.CONVERSION_OFFER)
      }else{
        setPhase(Phase.PAYING);
      }
    }
  }, [currentUser, phase]);
 
  useEffect(() => {
    (async() => {
      if(phase !== Phase.PAID) return;
      if(!currentUser.handler.isPaid()) {
        await currentUser.handler.updateStatus().catch((e: Error) => {
          setError(e);
        });
      }
      const updatedPayment = currentUser.handler.getPayment();
      const resources = currentUser.handler.getResources();
      console.log('ONPAID', updatedPayment, resources);
      sendResources(updatedPayment as ClientFacingPayment, resources as Resource[]);
      setPhase(Phase.NAG_REGISTER);
    })();
  }, [currentUser, phase])

  useEffect(() => {
    if(phase !== Phase.NAG_REGISTER) return;
    if(!isAnonymousUser(currentUser)){
      setPhase(Phase.THANK_YOU)
    }
  }, [phase, currentUser])

  if(error) {
    return <>
      <Header />
      <FatalError 
        text={<p>Hämtningen eller betalningen av din artikel misslyckades. Sessions-id: ${sessionId}. Bifoga sessions-id om du vill kontakta supporten på info@quiqly.se</p>} 
        onClose={closeQuiqly}
      />
    </>
  }

  switch (phase) {
    case Phase.START: 
    case Phase.INITIALIZING: 
      return <>
        <Header/>
        <BlockSpinner text="Vi hämtar nu din artikel"/>
      </>
    case Phase.CHANGING_USER: 
          return <>
            <Header/>
            <BlockSpinner text="Växlar användare"/>
          </>
    case Phase.INITIALIZED: 
        return <>
          <Header/>
          <BlockSpinner text="Laddar Swedbank Pay"/> {/* probably, add a little pay emoji/animation */}
        </>

    case Phase.CONVERSION_OFFER:
      const offer = currentUser.handler.getConversionOffer();
      if(!offer || !offer.href || !offer.code) { 
        setPhase(Phase.PAYING);
        return <>
          <Header/>
          <BlockSpinner />
        </>
      }
      return <>
        <Header/>
        <NagConversion 
          offer={offer} 
          onNoThanks={() => setPhase(Phase.PAYING)}
          onYes={() => {
            
          }}
        />
      </>

    case Phase.PAYING:
      const payment = currentUser.handler.getPayment();
      return <>
          <Header />
          <Purchase
            headline={payment?.title}
            price={payment?.price}
            name={anon.displayName ? anon.displayName.split(' ')[0] : undefined}
            includeChangePaymentLink={settings.token === true && user !== null}
            paymentUrl={payment?.paymentUrl}
            onChangePaymentMethod={() => {
              setSettings({...settings, token: !settings.token})
              setPhase(Phase.START);
            }}
            onPaid={async (args) => {
              console.log("PAID, args: ", args)
              try {
                setPhase(Phase.PAID);
              } catch(e) {
                setError(e as Error)
              }
            }}
          />
        </>
      case Phase.PAID:
        return <>
          <Header/>
          <BlockSpinner text="Processar betalning"/>
        </>

      case Phase.NAG_REGISTER:
        return <>
          <Header />
          <RegLogin
            login={false}
            onCreated={(user: User) => {}}
            onLoggedIn={async () => {}}
          />
          <Footer
            rightContent={
              <p style={{ textDecoration: 'underline' }} onClick={() => closeQuiqly()}>
                Nej tack, gå till artikeln
              </p>
            }
            leftContent={<div></div>}
          />
        </>

      case Phase.THANK_YOU:
        const _payment = currentUser.handler.getPayment();
        return <>
          <Header/>
          <Thankyou onClose={closeQuiqly}
            titleText={returningCustomer ? (currentUser.displayName ? <h2>Hej {currentUser.displayName.split(' ')[0]}!</h2> : <h2>Hej!</h2>) : undefined}
            contentText={
              returningCustomer ? 
                <>
                  <p>{params.partner !== "realtid" ? "Kul att du gillar" : "Du vill läsa"} <strong>{_payment?.title}</strong></p>
                  <p>{params.partner !== "realtid" ? "Njut av din läsning. " : ""}{!isNotDisabled(currentUser) ? "När du verifierat din e-mail kan du använda ditt konto." : ""}</p>
                </>:
                <>
                  <p>{params.partner !== "realtid" ? "Du har köpt" : "Du vill läsa"} <strong>{_payment?.title}</strong> för <strong>{_payment?.price}</strong></p>
                  <p>{params.partner !== "realtid" ? "Njut av din läsning. " : ""}{!isNotDisabled(currentUser) ? "När du verifierat din e-mail kan du använda ditt konto." : ""}</p>
                </>
            }
          />
        </>
  }

  return <>{JSON.stringify(anon.handler.getPayment())}</>
};

export default Payment2;
