import { CSSProperties, ReactNode, useEffect, useState } from 'react';
import { useMainContext } from '../context/MainContext';
import facebook from '../utils/img/facebook.svg';
import google from '../utils/img/google.svg';
import twitter from '../utils/img/twitter.svg';
import mail from '../utils/img/mail.svg';
import {
  loginWithEmailAndPassword,
  signUpWithEmailAndPassword,
  signUpWithFacebook,
  signUpWithGoogle,
  signUpWithTwitter
} from '../utils/Accounts';

import { User } from 'firebase/auth';

import styles from './Components.module.scss';
import { LinkishButton } from '../utils/HelperComponents';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import { isAnonymousUser } from '../utils/HelperFunctions';
import { apps } from '../context/Singletons';

interface RegLoginPageProps {
  login?: boolean
}
export const RegLoginPage = ({login = true} : RegLoginPageProps) => {
  const {state} = useLocation() as {state: {failedAutoLogin: boolean}};
  const nav = useNavigate();
  return <>
    <Header />
    <RegLogin 
      login={true} 
      onLoggedIn={() => nav('/pay')} 
      onCreated={() => nav('/registered')}
      text={state?.failedAutoLogin ? "Du är utloggad, logga in för att låsa upp artikeln" : undefined}
    />
    <Footer />  
  </>
}

interface RegLoginProps {
  login?: boolean,
  onLoggedIn?: (user: User) => void,
  onCreated?: (user: User) => void,
  text?: string
}
export function RegLogin({login = true, onLoggedIn = () => {}, onCreated = () => {}, text}: RegLoginProps) {
  const [error, setError] = useState<Error>();
  const [useEmail, setUseEmail] = useState(false);
  const [_login, _setLogin] = useState(login);
  const { user } = useMainContext();

  useEffect(() => {
    if ((user && !isAnonymousUser(user)) && onLoggedIn) onLoggedIn(user);
  }, [user, onLoggedIn]);

  const selected = (provider: "twitter" | "facebook" | "google" | "mail") => {
    const catcher = (e: { code: string }) => {
      console.log(e);
      if(e.code === 'auth/account-exists-with-different-credential')
        setError(new Error("Kontot är registrerat med en annan inloggningsmetod (mail, Google, Twitter, eller Facebook)"))
      else
        setError(new Error("Ett okänt fel uppstod"))
    }
    switch(provider) {
        case "google": 
          signUpWithGoogle(apps.auth.main).catch(catcher);
          break;
        case "facebook": 
          signUpWithFacebook(apps.auth.main).catch(catcher);
          break;
        case "twitter":
          signUpWithTwitter(apps.auth.main).catch(catcher);
          break;
        case "mail":
          setUseEmail(true);
          break;
        default:
          setError(new Error('Panic in login component'));
      }
    
  }

  const submitted = (user: string, password: string) => {
    const completeLogin = async () => {
      try {
        await loginWithEmailAndPassword(user, password, apps.auth.main);
      } catch (e: unknown) {
          const err = e as { code: string };

          if (err.code === 'auth/user-disabled') setError(new Error('Du måste först verifiera ditt konto'))
          else if(err.code === 'auth/user-not-found') setError(new Error('Det finns ingen användare med den e-mailen'))
          else setError(e as Error);
      }
    };
    const completeRegistration = async () => {
      try {
        const userCredentials = await signUpWithEmailAndPassword(user, password, apps.auth.main);
        onCreated(userCredentials.user);
      } catch (e: unknown) {
        if ((e as { code: string }).code === 'auth/email-already-in-use') setError(new Error('Mailen används redan'));
        else setError(e as Error);
      }
    };
    _login ? completeLogin() : completeRegistration();
  }

  return <div className={styles.componentContainer}>
    <div className={styles.headlineTitle}>
      <h2>{_login ? "Logga in hos Quiqly" : "Skapa ett konto hos Quiqly"}</h2>
      {error?.message && <p style={{ fontWeight: 'bold', color: 'red' }}>{error.message}</p>}
      <p>{text ?? "Få tillgång till dina upplåsta artiklar och kvitto efter varje köp."}</p>
    </div>
    {!useEmail && <ProceedWith login={_login} onSelected={selected}/>}
    {useEmail && <MailForm login={_login} onSubmit={submitted} />}
    <p className={styles.extraInfoText} style={{color: "black", fontSize: "small"}}>
      {_login ? `Inget konto? ` : `Har du redan ett konton? `}
      <LinkishButton onClick={() => _setLogin(!_login)} style={{color: "black"}}>{_login ? 'Skapa ett konto' : 'Logga in'}</LinkishButton>
    </p>
    {useEmail && <p className={styles.extraInfoText} style={{marginTop: 0, fontSize: "small"}}>
        <LinkishButton onClick={() => setUseEmail(false)}>Byt inloggningsmetod</LinkishButton>
    </p>}
    {!_login && <p className={styles.extraInfoText} style={{marginTop: 0, fontSize: "small", textAlign: "center", color: "grey"}}>När du registrerar ett användarkonto accepterar du våra <a href="http://quiqly.se/anvandarvillkor/" target={'_blank'} rel='noreferrer' style={{color: "grey"}}>användarvillkor</a> och <a href="http://quiqly.se/anvandarvillkor/" target={'_blank'} rel='noreferrer' style={{color: "grey"}}>integritetspolicy</a>.</p>}
  </div>

}

function ProceedWith({onSelected, login = true}: {onSelected: (provider: "twitter" | "facebook" | "google" | "mail") => void, login?: boolean}) {
  return <>
    <ProceedButton onClick={() => onSelected("google")} style={{marginTop: "0"}}><img src={google} style={{ width: "30px", height: "30px", marginRight: "5px" }} alt='google' /><span>Fortsätt med Google</span></ProceedButton>
    <ProceedButton onClick={() => onSelected("facebook")}><img src={facebook} style={{ width: "30px", height: "30px", marginRight: "5px" }} alt='facebbok' /><span>Fortsätt med Facebook</span></ProceedButton>
    <ProceedButton onClick={() => onSelected("twitter")}><img src={twitter} style={{ width: "30px", height: "30px", marginRight: "5px" }} alt='twitter' /><span>Fortsätt med Twitter</span></ProceedButton>
    <ProceedButton onClick={() => onSelected("mail")}><img src={mail} style={{ width: "30px", height: "30px", marginRight: "5px" }} alt='e-mail' /><span>{login ? "Logga in med mail" : "Skapa konto med mail"}</span></ProceedButton>
  </>
}

interface MailFormProps {
  onSubmit: (uname: string, password: string) => void,
  login?: boolean, onError?: (e: Error) => void,
}
function MailForm({onSubmit, login = true, onError = () => {}}: MailFormProps) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const nav = useNavigate();

  return <>
    <div className={styles.inputsWrapper}>
      <input
        type='email'
        id='emailinput'
        aria-describedby='emailHelp'
        placeholder='Email'
        style={{
          border: '1px solid black',
          borderTopRightRadius: '5px',
          borderTopLeftRadius: '5px'
        }}
        pattern='^\S+@\S+\.\S+$'
        value={email}
        onChange={(e) => {
          setEmail(e.target.value);
        }}
      />
      <input
        type='password'
        id='passwordinput'
        placeholder='Lösenord'
        style={{
          border: '1px solid black',
          borderTop: 'none',
          borderBottomLeftRadius: !login ? '0px' : '5px',
          borderBottomRightRadius: !login ? '0px' : '5px'
        }}
        value={password}
        pattern='(?=.*[0-9a-zA-Z]).{6,}'
        onChange={({ target: { value } }) => setPassword(value)}
      />
      {login && <LinkishButton style={{alignSelf: "baseline", fontSize: "smaller", color: "grey", marginLeft: "-5px", marginTop: "5px"}} onClick={() => nav('/reset')}>Glömt lösenordet?</LinkishButton>}
    </div>
    <button
      type='button'
      className={styles.submitButton}
      onClick={(e) => {
        e.preventDefault();
        onSubmit(email, password);
      }}
    >{login ? "Logga in" : "Registrera"}</button>
  </>
}

function ProceedButton({children, onClick, style={}}: {children: ReactNode, onClick?: () => void, style?: CSSProperties}) {
  return <button className={styles.proceedButton} style={style} onClick={onClick}>
    {children}
  </button>
}