import { ReactNode } from 'react';
import styles from './Components.module.scss';

type FatalErrorProps = { text: ReactNode, onClose: () => void };
const FatalError = ({ text = "Något gick fel i hämtningen av din artikel. Försök igen senare!", onClose }: FatalErrorProps) => {
  return (
    <div className={styles.componentContainer}>
        <div className={styles.headlineTitle}>
          <h2>Något gick fel!</h2>
          {text}
        </div>
        <button className={styles.submitButton} onClick={() => onClose()}>
          Gå tillbaka till tidningen
        </button>
      </div>
  );
};
export default FatalError;
