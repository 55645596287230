
interface VarsetterProps {
    fontColorStrong?: string;
    fontColorWeak?: string;
    bgColor?: string;
    bgImage?: string;
    condition?: string;
}
export const Varsetter = ({fontColorStrong, fontColorWeak, bgColor, bgImage, condition = "*"}: VarsetterProps) => <style>
    {`${condition} {`}
        {fontColorStrong && `--font-color-strong: ${fontColorStrong} !important;`}
        {fontColorWeak && `--font-color-weak: ${fontColorWeak} !important;`}
        {bgColor && `--bg-color: ${bgColor} !important;`}
        {bgImage && `--bg-image: ${bgImage} !important;`}
    {'}'}
</style>
