import { getWindowAncestor } from "../utils/HelperFunctions";
import { isPopup } from "./Helpters";
import { ClientFacingPayment, Resource } from "./quiqly-api";

export function sendResources(payment: ClientFacingPayment, resources: Resource[]) {
  const ancestor = getWindowAncestor();
  ancestor.postMessage(JSON.stringify({ command: 'QUIQLY_PAID', payment, resources}), '*'); // FIXME security
  if(ancestor === window){
    console.error('Lost ancestor');

    //FIXME move this to library function, DRY
    const url = new URL(resources[0].resourceId);
    const newUrlSearchParams = new URLSearchParams(url.search);
    newUrlSearchParams.set('quiqly', JSON.stringify({open: true, otp: payment.otp}));
    url.search = newUrlSearchParams.toString();
    window.location.href = url.toString();
  }
}

export function closeQuiqly() {
  if(isPopup()) {
    return window.close();
  }
  const ancestor = getWindowAncestor();
  ancestor.postMessage(JSON.stringify({ command: 'QUIQLY_CLOSE' }), '*');
  if(ancestor === window){
    console.error('Lost ancestor');
    // FIXME -> Use redirect in this case
  }
}
