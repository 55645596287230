import { CSSProperties, ReactNode } from 'react';

export const HorizontalDivider = ({ content }: { content?: string }) => {
  return (
    <p
      style={{
        width: '100%',
        borderBottom: '.1em solid #000',
        textAlign: 'center',
        lineHeight: '.1em',
        margin: '2em 0'
      }}
    >
      {content && <span style={{ backgroundColor: '#fff', padding: '0 10px' }}>{content}</span>}
    </p>
  );
};

export const BackArrow = ({ text, size, onClick }: { text?: string; size?: number; onClick?: () => void }) => {
  return (
    <button style={{ border: 'none', backgroundColor: '#ffffff00' }} onClick={onClick}>
      <span style={{ color: 'grey', fontSize: size ?? 14 }}>
        <span style={{ fontWeight: 'bold', marginRight: '.2em', fontSize: size ? size + 4 : 16 }}>&#8592;</span>
        {text}
      </span>
    </button>
  );
};

export const ThreeDotsLoader = () => (
  <div
    style={{
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      alignSelf: 'center',
      justifySelf: 'center',
      fontSize: '1rem',
      maxWidth: '30px'
    }}
  >
    <svg width='120' height='30' viewBox='0 0 120 30' xmlns='http://www.w3.org/2000/svg' fill='#fff'>
      <circle cx='15' cy='15' r='15'>
        <animate
          attributeName='r'
          from='15'
          to='15'
          begin='0s'
          dur='0.8s'
          values='15;9;15'
          calcMode='linear'
          repeatCount='indefinite'
        />
        <animate
          attributeName='fill-opacity'
          from='1'
          to='1'
          begin='0s'
          dur='0.8s'
          values='1;.5;1'
          calcMode='linear'
          repeatCount='indefinite'
        />
      </circle>
      <circle cx='60' cy='15' r='9' fillOpacity='0.3'>
        <animate
          attributeName='r'
          from='9'
          to='9'
          begin='0s'
          dur='0.8s'
          values='9;15;9'
          calcMode='linear'
          repeatCount='indefinite'
        />
        <animate
          attributeName='fill-opacity'
          from='0.5'
          to='0.5'
          begin='0s'
          dur='0.8s'
          values='.5;1;.5'
          calcMode='linear'
          repeatCount='indefinite'
        />
      </circle>
      <circle cx='105' cy='15' r='15'>
        <animate
          attributeName='r'
          from='15'
          to='15'
          begin='0s'
          dur='0.8s'
          values='15;9;15'
          calcMode='linear'
          repeatCount='indefinite'
        />
        <animate
          attributeName='fill-opacity'
          from='1'
          to='1'
          begin='0s'
          dur='0.8s'
          values='1;.5;1'
          calcMode='linear'
          repeatCount='indefinite'
        />
      </circle>
    </svg>
  </div>
);

interface ButtonProps {
  onClick?: () => void;
  style?: CSSProperties;
  children: ReactNode;
  dark?: boolean;
}
const buttonStyles = {
  general: {
    cusrsor: 'pointer'
  },
  dark: {
    background: 'black',
    color: 'white'
  },
  light: {
    border: '1px solid black',
    background: 'white',
    color: 'black'
  }
};
export const Button = ({ style, onClick, children, dark = false }: ButtonProps) => (
  <button onClick={onClick} style={{ ...buttonStyles[dark ? 'dark' : 'light'], ...buttonStyles.general, ...style }}>
    {children}
  </button>
);

interface LinkishButtonProps {
  onClick?: () => void;
  style?: CSSProperties;
  children: ReactNode;
}
const linkStyledButton: CSSProperties = {
  background: 'none',
  border: 'none',
  padding: '0',
  textDecoration: 'underline',
  cursor: 'pointer'
};

export const LinkishButton = ({ style, onClick, children }: LinkishButtonProps) => (
  <span onClick={onClick} style={{ ...linkStyledButton, ...style }}>
    {children}
  </span>
);

export const QuiqlyLoader = ({ size = 4, style }: { size?: number; style?: CSSProperties }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <span
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: `${size}em`,
          height: `${size}em`,
          backgroundColor: 'transparent',
          borderRadius: '50%',
          borderTop: '4px solid rgb(0, 0, 0)',
          borderLeft: '4px solid rgb(0, 0, 0)',
          animation: ' spinner 0.6s linear infinite',
          position: 'absolute',
          ...style
        }}
      ></span>
      <p style={{ fontSize: `${size / 4}em`, margin: 0 }}>quiqly</p>
    </div>
  );
};

export const BlockSpinner = ({ size = 15, text }: { size?: number, text?: string }) => (
  <div>
    {text && <p style={{textAlign:"center", marginTop: "3em"}}>{text}</p>}
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: `100%`,
        height: `${size}em`,
        animation: 'spinner 1s linear infinite'
      }}
    >
      <div
        style={{
          border: '0.25rem solid currentColor',
          borderRight: '0.25rem solid transparent',
          width: '2rem',
          height: '2rem',
          borderRadius: '100%',
          animation: 'rotating 2s linear infinite'
        }}
      ></div>
    </div>
  </div>
);
