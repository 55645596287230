import { onAuthStateChanged, signInAnonymously, signInWithCustomToken, User } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { QuiqlyPayment2 } from '../api/quiqly-api';
import { isAnonymousUser } from '../utils/HelperFunctions';
import { apps } from './Singletons';

export type UserWithHandler = User&{handler:QuiqlyPayment2};
export const useAnonUser = (otpData: {token: string, anon: boolean}|false|undefined) => {
    const [anon, setAnonUser] = useState<UserWithHandler>();
    const [otpDone, setOtpDone] = useState(otpData === false);

    useEffect(() => {
        console.log("[FB-ANON] otpData", otpData);
        if(otpData === false) return setOtpDone(true);
        if(otpData === undefined) return;
        if(otpData.anon === false) return setOtpDone(true);

        signInWithCustomToken(apps.auth.anon, otpData.token).then(user => {
            console.log('[FB-ANON] Logged in with OTP');
            setOtpDone(true);
        }).catch(error => {
            console.log(error);
        })
    }, [otpData]);

    useEffect(() => {
        if(otpDone === undefined) return;
        const unSubscribeAuth = onAuthStateChanged(apps.auth.anon, (anonUser) => {
            if(!otpDone) return;
            console.log(`[FB-ANON] User initialized: ${anonUser?.uid}, isAnonymous?${anonUser ? isAnonymousUser(anonUser) : false}`);

            if (anonUser) {
                if(anon?.uid === anonUser.uid) {
                    console.log("[FB-ANON] Skipping resetting user - already here");
                }else{
                    console.log(`[FB-ANON] Reset user, old: ${anon?.uid}, new: ${anonUser.uid}`);
                    (anonUser as UserWithHandler).handler = new QuiqlyPayment2(anonUser);
                    setAnonUser(anonUser as UserWithHandler);
                }
            }
            if (anonUser === null) {
                signInAnonymously(apps.auth.anon)
                    .then(() => console.log('[FB-ANON] Logged in as anon, since this did not happen automatically'))
                    .catch((e) => console.log('[FB-ANON] Failed to log in anon', e));
            }
        });
        return () => {
            unSubscribeAuth();
        };
    }, [anon?.uid, otpDone]);
    return anon;
}

export const useMainUser = (otpData: {token: string, anon: boolean}|false|undefined) => {
    const [user, setUser] = useState<UserWithHandler|null>();
    const [otpDone, setOtpDone] = useState(otpData === false);

    useEffect(() => {
        console.log("[FB-MAIN] otpData", otpData);
        if(otpData === false) return setOtpDone(true);
        if(otpData === undefined) return;
        if(otpData.anon === true) return setOtpDone(true);

        signInWithCustomToken(apps.auth.main, otpData.token).then(user => {
            console.log('[FB-MAIN] Logged in with OTP', user);
            setOtpDone(true);
        }).catch(error => {
            console.log(error);
        })
    }, [otpData]);

    useEffect(() => {
        if(!otpDone) return;
        const unSubscribeAuth = onAuthStateChanged(apps.auth.main, (mainUser) => {
            console.log(`[FB-MAIN] User initialized: ${mainUser?.uid}, isAnonymous?${mainUser ? isAnonymousUser(mainUser) : false}`);
        
            if (mainUser) {
                if(user?.uid === mainUser.uid) {
                    console.log("[FB-MAIN] Skipping resetting user - already here");
                }else{
                    console.log(`[FB-MAIN] Reset user, old: ${user?.uid}, new: ${mainUser.uid}`);
                    (mainUser as UserWithHandler).handler = new QuiqlyPayment2(mainUser);
                    setUser(mainUser as UserWithHandler);
                }
            }
            if (mainUser === null) {
                console.log('[FB-MAIN] No user');
                setUser(null);
            }
        });
        return () => {
            unSubscribeAuth();
        };
    }, [user?.uid, otpDone]);
    return user;
}
