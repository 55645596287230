import { Routes, Route, Navigate, useNavigate, useParams } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { Verify } from './components/Redesign';
import { RegLoginPage } from './components/RegisterOrLogin';
import { useEffect, useState } from 'react';
import Header from './components/Header';
import { BlockSpinner } from './utils/HelperComponents';
import ResetPasword from './components/ResetPassword';
import { FAQ } from './components/FAQ';
import Footer from './components/Footer';
import { closeQuiqly } from './api/cross-frame';
import { useMainContext } from './context/MainContext';
import Payment2 from './components/Payment2';
import { apps } from './context/Singletons';
import Thankyou from './components/Thankyou';

const Logout = () => {
  const nav = useNavigate();
  const {next} = useParams()
  signOut(apps.auth.main).then(() => {
    console.log('signed out');
    nav(next ? `/${next}` : '/pay');
  });
  return <><Header/><BlockSpinner /></>;
};

const Registered = () => {
  const nav = useNavigate();
  useEffect(()=> {
    const timeout = setTimeout(() => {
      nav('/logout/login');
    }, 6000);
    return () => clearTimeout(timeout);
  }, [nav])

  return <div style={{width: "100%", minHeight: "200px"}}>
      <p style={{textAlign: "center"}}>När du verifierat din e-mail kan du logga in! Du skickas nu vidare till inloggningssidan.</p>
    </div>
}

const Unlock = () => {
  const {user} = useMainContext();
  const nav = useNavigate();
  useEffect(() => {
    if(!user) {
      nav('/login', {state: {failedAutoLogin: true}});
    } else {
      nav('/pay');
    }
  }, [nav, user])
  return <><Header/><BlockSpinner /></>;
}

const App = () => {
  console.log(`Path is ${window.location.href}`);
  const {params: {page}} = useMainContext();
  const [renav, setRenav] = useState(page !== undefined);
  const nav = useNavigate();
  useEffect(() => {
    if(page && renav) {
        setRenav(false);
        nav(page);
        console.log('Navved', page);
    }
  }, [page, renav, nav])

  return (
    <>
      <Routes>
        <Route path='/' element={<Navigate to='pay' />} />
        <Route path='pay' element={<Payment2 />} />

        <Route
          path='reg'
          element={<RegLoginPage login={false} />}
        />
        <Route
          path='login'
          element={<RegLoginPage login />}
        />

        <Route path='reset' 
          element={<>
            <Header />
            <ResetPasword />
            <Footer
              rightContent={
                <p style={{ textDecoration: 'underline' }} onClick={() => closeQuiqly()}>
                  Nej tack, gå till artikeln
                </p>
              }
            />
          </>} 
        />
        <Route path='logout' element={<Logout />} />
        <Route path='logout/:next' element={<>
          <Header rightContent={<></>} />
          <Logout />
        </>} />
        <Route path='tos' element={<>PLACEHOLDER TOS</>} />
        <Route path='registered' 
          element={<>
            <Header />
            <Registered />
          </>}
        />
        <Route path='verify-email/:verificationCode' element={<Verify />} />
        <Route path='faq' element={<FAQ />}/>
        <Route path='what-is-quiqly' element={<FAQ startAt={"Vad är Quiqly?"}/>}/>
        <Route path='unlock' element={<Unlock />} />
        <Route path='thankyou-test' element={<><Header/><Thankyou onClose={() => {/**/}}/><Footer/></>} />
      </Routes>
    </>
  );
};

export default App;
