import { useMainContext } from '../context/MainContext';
import { LinkishButton } from '../utils/HelperComponents';

import styles from './Components.module.scss';

interface NagConversionParams {
  offer: {
    code?: string;
    href?: string;
    title?: string;
    text?: string;
  };
  onNoThanks: () => void;
  onYes: () => void 
}
export const NagConversion = ({ offer, onNoThanks, onYes }: NagConversionParams) => {
  const { user } = useMainContext();

  return (
    <>
      <div className={styles.componentContainer}>
        <div
          className={styles.headlineTitle}
          style={{ justifyContent: 'flex-start', alignItems: 'flex-start', textAlign: 'left' }}
        >
          {user && <h3>Hej{user.displayName ? " " + user.displayName?.split(' ')[0] : ""}!</h3>}
          <h2 style={{marginTop: 0, marginBottom: "0.8em"}}>{offer.title ?? "Kul at du gillar artiklarna!"}</h2>
          <p>{offer.text ?? "Vi här märkt at du ofta läser artiklarna här, därför finns ett erbjudande till dig!"}</p>
          {offer.code && <p>Rabattkod: <code style={{fontFamily: "monospace", background: "lightgray", padding: "5px"}}>{offer.code}</code></p>}
        </div>
        {
          offer.href && 
          <button type='button' className={styles.submitButton} onClick={(e) => {
            window.open(offer.href)
            onYes()
          }}>
            Till erbjudande
          </button>
        }
        <p className={styles.extraInfoText} style={{fontSize: "small"}}>
            <LinkishButton onClick={() => onNoThanks()}>Nej tack, jag vill köpa artikeln!</LinkishButton>
        </p>
      </div>

      <div></div>
    </>
  );
};
