import { User } from 'firebase/auth';
import { sessionId } from '../utils/GlobalSessionId';
import { BasedSessionFetch, createBasedSessionFetch } from './Helpters';

interface URLAndToken {
  articleUrl: string;
  idToken: string | null;
}
type PayResult =
  | {
      success: true;
      articleInfo: {
        paymentUrl: string;
        title: string;
        price: number;
      };
      response: Response;
    }
  | { success: false; response: Response };
export function payForArticle({ articleUrl, idToken }: URLAndToken): Promise<PayResult> {
  const prefix = process.env.REACT_APP_QUIQLY_API_URL;
  return fetch(`${prefix}/pay`, {
    method: 'POST',
    body: JSON.stringify({ articleUrl, status: 'completed' }),
    headers: idToken
      ? { Authorization: `Bearer ${idToken}`, 'Content-Type': 'application/json' }
      : { 'Content-Type': 'application/json' },
    credentials: 'include'
  })
    .then(async (res) => {
      if (res.status === 200 || res.status === 201) {
        return {
          success: true,
          articleInfo: await res.json(),
          response: res
        };
      } else {
        return {
          success: false as false,
          response: res
        };
      }
    })
    .catch((e) => {
      throw new Error('Unexpected payment failure');
    });
}

interface IDToken {
  idToken: string;
}
export function claimSession({ idToken }: IDToken): Promise<void> {
  const prefix = process.env.REACT_APP_QUIQLY_API_URL;
  return fetch(`${prefix}/claimSession`, {
    method: 'GET',
    headers: idToken ? { Authorization: `Bearer ${idToken}` } : {},
    credentials: 'include'
  })
    .then(async (res) => {
      if (res.status !== 200) {
        throw new Error('Claim failed');
      }
      return;
    })
    .catch((e) => {
      throw new Error('Unexpected claim failure');
    });
}

type GetArticleResult =
  | {
      success: true;
      articleText: string;
      response: Response;
    }
  | { success: false; response: Response };
export function getArticle({ articleUrl, idToken }: URLAndToken): Promise<GetArticleResult> {
  const prefix = process.env.REACT_APP_QUIQLY_API_URL;
  fetch(`${prefix}/payments/search?articleUrl=${encodeURIComponent(articleUrl || '')}`, {
    headers: idToken ? { Authorization: `Bearer ${idToken}` } : {},
    credentials: 'include'
  }).then((res) => console.log(res.status, res));

  console.log('Click getArticle');
  return fetch(`${prefix}/getArticle?articleUrl=${encodeURIComponent(articleUrl || '')}`, {
    headers: idToken ? { Authorization: `Bearer ${idToken}` } : {},
    credentials: 'include'
  }).then(async (res) => {
    if (res.status !== 200) {
      return {
        success: false,
        response: res
      };
    }
    return {
      success: true,
      articleText: await res.text(),
      response: res
    };
  });
}

type ClaimReceiptResponse =
  | { success: true; articleText: string; response: Response }
  | { success: false; response: Response };
export function claimReceipt({ receipt }: { receipt: string }): Promise<ClaimReceiptResponse> {
  console.log('Claiming receipt id');
  const prefix = process.env.REACT_APP_QUIQLY_API_URL;
  return fetch(`${prefix}/claimReceipt?receipt=${encodeURIComponent(receipt || '')}`, {
    credentials: 'include'
  })
    .then((res) => {
      if (res.status !== 200) {
        throw res;
      }
      return res;
    })
    .then(async (res) => {
      const articleText = await res.text();
      return { success: true, articleText: articleText, response: res };
    })
    .catch((res: Response) => {
      return { success: false, response: res };
    });
}

export interface ArticleListing {
  articleTitle: string;
  articleUrl: string;
}
export interface VerifyAccountResponse {
  articles: ArticleListing[];
  loginToken: string;
}
export const verifyAccount = ({ verificationCode }: { verificationCode: string }): Promise<VerifyAccountResponse> => {
  const prefix = process.env.REACT_APP_QUIQLY_API_URL;
  return fetch(`${prefix}/verifyAccount?code=${verificationCode}`).then((res) => {
    if (res.status !== 200) {
      throw new Error('Bad status');
    }
    return res.json() as Promise<VerifyAccountResponse>;
  });
};

export const OTPLogin = (otp: string) => {
  return createBasedSessionFetch(process.env.REACT_APP_QUIQLY_API_URL || '')(`/OTPLogin?otp=${otp}`).then((res) => res.json() as Promise<{token: string, anon: boolean}>);
}


export type Resource = ResourceHeader & ResourceBody
export type ResourceHeader = {
  resourceId: string,
  resourceType?: string,
  title?: string;
}
export type ResourceBody = HTMLResource;
export type HTMLResource = {
  resourceType: "HTML",
  title: string;
  html: string;
};
export interface ClientFacingPayment {
  id: string;
  title: string;
  claimed: boolean;
  status: string;
  paymentUrl: string | null;
  ownerId: string,
  price: string,
  usingToken: boolean,
  resourceId: string,
  otp: string
}
export interface PostPaymentResponse {
  payments: ClientFacingPayment[], // Should have resourceheader for correct Resource
  resources: (Resource|ResourceHeader)[],
  conversionOffer?: {
      code?: string,
      href?: string,
      title?: string,
      text?: string
  }
}
export interface PaymentPostSettings {
  resourceId: string,
  settings: {
      token: boolean,
      lookup: boolean,
      create: boolean
  }
}

export class QuiqlyPayment2 {
  user: User;
  paymentId?: string;
  client: BasedSessionFetch;
  lastResponse: PostPaymentResponse | null = null;

  constructor(user: User) {
    this.user = user;
    this.client = createBasedSessionFetch(process.env.REACT_APP_QUIQLY_API_URL || '');
  }

  async initialize(params: PaymentPostSettings) {
    const res = await this.client(`v2/payments/initialize`, {method: "POST", headers: {...await getAuthHeader(this.user), 'content-type': "application/json"}, body: JSON.stringify(params)});
    const data : PostPaymentResponse = await res.json();
    this.lastResponse = data;
    this.paymentId = this.getPayment()?.id;
  }
  async updateStatus() { // FIXME - all of these should just retry until it works
    if(!this.paymentId) { throw new Error('No payment id'); }
    const res = await this.client(`v2/payments/${this.paymentId}`, {method: "GET", headers: await getAuthHeader(this.user)});
    const data : PostPaymentResponse = await res.json();
    this.lastResponse = data;
  }

  async transferTo(qp2: QuiqlyPayment2) {
    const res = await this.client(`v2/payments/${this.paymentId}/transfer/${qp2.user.uid}`, {method: "GET", headers: await getAuthHeader(this.user)});
    if(res.status === 200){
      console.log(`Transferred payment ${this.paymentId} to user: ${qp2.user.uid}`);
      qp2.lastResponse = this.lastResponse;
      qp2.paymentId = this.paymentId;
      qp2.lastResponse?.payments.forEach(p => {
        p.ownerId = qp2.user.uid;
      });
      this.lastResponse = null;
      this.paymentId = undefined;
    }else{
      console.error('Failed to transfer payment');
      throw new Error('Transfer failed');
    }
  }

  async sendEvent({name, resourceId, params = {}} : {name: string, resourceId: string, params?: Record<string,unknown>}) {
    this.client('events', {
      method: "POST",
      headers: {
        'content-type': 'application/json',
        ...await getAuthHeader(this.user)
      },
      body: JSON.stringify({
        name,
        resourceId,
        sessionId: sessionId,
        params
      })
    }).catch(e => console.error(e));
  }

  getPayment() { return this.lastResponse?.payments?.[0]; } //For now, always just one payment, even though the type suggests otherwise
  getResources() { return this.lastResponse?.resources; }
  getConversionOffer() { return this.lastResponse?.conversionOffer; }
  isPaid() { return this.getPayment()?.status === "Paid" && this.getPayment()?.claimed } //Bit finicky
  isFailed() { return this.getPayment()?.status === "Failed" }
  isInitialized() { return this.getPayment()?.status === "Initialized" }
}

const getAuthHeader = async (user: User) => ({ Authorization: `Bearer ${await user.getIdToken()}` })