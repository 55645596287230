import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { closeQuiqly } from '../api/cross-frame';
import { useMainContext } from '../context/MainContext';
import styles from './Components.module.scss';

type HeaderProps = { leftContent?: any; rightContent?: any, closeable?: boolean };
const Header = ({ leftContent, rightContent, closeable = true }: HeaderProps) => {
  const { user, mode } = useMainContext();
  const nav = useNavigate();
  
  return (
    <>
      <div className={`${styles.header} header`}>
        <div className={styles.headerLogout} style={{justifyContent: "left"}} onClick={() => (nav("/faq"))}>
          {leftContent ?? <p>FAQ</p>}
        </div>
        <h1>{'Quiqly'}</h1>
        <div className={styles.headerLogout} style={{justifyContent: "right"}} onClick={() => (user ? nav('/logout') : nav('/login'))}>
          {rightContent ?? <p>{user ? 'Logga ut' : 'Logga in'}</p>}
        </div>
        {closeable && mode !== "popup" && <div className={styles.closeButton} onClick={() => closeQuiqly()}>
          <FontAwesomeIcon icon={faClose} color="black"></FontAwesomeIcon>
        </div>}
      </div>
    </>
  );
};

export default Header;
