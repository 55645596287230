import styles from './Components.module.scss';
import Footer from './Footer';
import Header from './Header';
import Collapsible from 'react-collapsible';
import { ReactNode, useState } from 'react';
const FAQs : {q: string, a: string|ReactNode}[] = [
  {
    q: "Vad är Quiqly?",
    a: "Med Quiqly kan du köpa enskilda artiklar direkt från tidningarnas plattformar – utan att prenumerera. Vi tar endast den avgift som står på artikeln, inget annat."
  },
  {
    q: "Varför ska jag skapa ett Quiqly-konto?",
    a: "Med ett Quiqly-konto får du tillgång till alla dina upplåsta artiklar och ett kvitto efter varje köp. Dessutom går varje köp ännu snabbare och du kan du komma åt dina artiklar på alla dina enheter."
  },
  {
    q: "Var hittar jag mina köpta artiklar?",
    a: "Du kan läsa dina köpta artiklar om och om igen genom att söka upp dem och logga in med ditt Quiqly-konto." 
  },
  {
    q: "Jag har glömt mitt lösenord, vad gör jag?",
    a: "Du kan enkelt återställa ditt lösenord genom att klicka ”glömt lösenord” i steget där du loggar in och följa återställnings-stegen."
  },
  {
    q: "Hur gör jag en betalning?",
    a: "Du genomför en betalning genom att välja ett betalsätt som passar dig, följer de steg som du ombeds att göra. Voilá, du har precis köpt en artikel." 
  },
  {
    q: "Vem hanterar min betalning?",
    a: "Ditt köp hanteras tryggt av vår betalpartner Swedbank Pay. Har du frågor kring ditt köp ber vi dig att kontakta dem. Du når dem på: https://www.swedbankpay.se/kontakta-oss"
  },
  {
    q: "Måste jag vara kund hos Swedbank för att kunna betala min artikel?",
    a: "Nej, du kan ha vilken bank som helst för att genomföra ett köp."
  },
  {
    q: "Är det säkert att betala med Quiqly?",
    a: "Ja, ditt köp hanteras tryggt och säkert av vår betalpartner Swedbank Pay."
  },
  {
    q: "Sparar Quiqly mina kortuppgifter?",
    a: "Nej, Quiqly sparar- eller lagrar aldrig några kortuppgifter. Däremot kan du, tryggt och säkert, välja att spara dina kortuppgifter hos vår betalpartner Swedbank Pay för att snabbare kunna göra ett köp nästa gång. Detta gör du genom att klicka ”Spara kortet för framtida köp”."
  },
  {
    q: "Hur ändrar jag mina sparade kortuppgifter?",
    a: "Dina kortuppgifter sparas tryggt och säkert hos vår betalpartner Swedbank Pay. Vill du ändra dessa klickar du ”Byt betalmetod” och lägger till ett nytt kort." 
  },
  {
    q: "Hur får jag ett kvitto?",
    a: "Genom att skapa Quiqly-konto får du ett kvitto efter varje köp skickat till din mejl – dessutom kan du komma åt dina artiklar på alla dina enheter."
  },
  {
    q: "Hur gör jag för att komma i kontakt med er?",
    a: "Har du frågor, funderingar eller synpunkter kring tjänsten är du välkommen att kontakta oss. Du når oss enklast på Messenger via vår hemsida www.quiqly.se, alternativt kan du mejla till nedanstående adress: info@quiqly.se"
  },
  {
    q: "Hur kommer jag åt en artikel som jag redan låst upp?",
    a: "Genom att skapa Quiqly-konto kan du komma åt dina artiklar på alla dina enheter, om och om igen. Dessutom går varje köp snabbare att genomföra och du får du ett kvitto efter varje köp." 
  },
  {
    q: "Hur hanterar ni mina användaruppgifter (integritetspolicy/GDPR)?",
    a: <>Quiqly värdesätter dina personuppgifter, du ska alltid känna dig trygg när du lämnar dina personuppgifter till oss. Vi skyddar informationen och behandlar den på ett korrekt sätt i enlighet med GDPR.\nDu kan läsa mer om vår integritetspolicy (GDPR) <a rel="noreferrer" href="http://quiqly.se/integritetspolicy/" target="_blank">här</a></>
  },
  {
    q: "Hittar du inte det du letar efter?",
    a: "Vi hjälper självklart till om du har några andra funderingar eller frågor. Du når oss enklast på Messenger, alternativt kan du mejla till nedanstående adress: info@quiqly.se"
  }
];

export const FAQ = ({startAt = null}: {startAt?: string|null}) => {
  const [openQ, setOpenQ] = useState<string|null>(startAt);
  return (
    <>
      <Header leftContent={<></>} />
      <div style={{padding: "1em"}}>
        <div className={styles.componentContainer} style={{maxWidth: "unset"}}>
        {FAQs.map(({q, a}) => (
                <Collapsible trigger={q} open={q === openQ} onOpening={() => setOpenQ(q)} onClosing={() => setOpenQ(null)} transitionTime={100} className={styles.Collapsible} openedClassName={styles.Collapsible} triggerClassName={styles.CollapsibleTrigger} triggerOpenedClassName={`${styles.CollapsibleTrigger} ${styles.CollapsibleTriggerOpen}`} contentInnerClassName={styles.CollapsibleContent}>
                    <p>{a}</p>
                </Collapsible>
            ))}
        </div>
      </div>
      <Footer />
    </>
  );
};
