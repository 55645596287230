import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const {
    REACT_APP_FIREBASE_apiKey: apiKey,
    REACT_APP_FIREBASE_authDomain: authDomain,
    REACT_APP_FIREBASE_databaseURL: databaseURL,
    REACT_APP_FIREBASE_projectId: projectId,
    REACT_APP_FIREBASE_storageBucket: storageBucket,
    REACT_APP_FIREBASE_messagingSenderId: messagingSenderId,
    REACT_APP_FIREBASE_appId: appId,
    REACT_APP_FIREBASE_measurementId: measurementId
  } = process.env;

// We use two apps to manage auto login from creating an account, and handovers - we can be logged in on both accounts
const main = initializeApp({
    apiKey,
    authDomain,
    databaseURL,
    projectId,
    storageBucket,
    messagingSenderId,
    appId,
    measurementId
}, "quiqly-user");
const anon = initializeApp({
    apiKey,
    authDomain,
    databaseURL,
    projectId,
    storageBucket,
    messagingSenderId,
    appId,
    measurementId
}, "quiqly-anon");

export const apps = {
    main,
    anon,
    auth: {
        main: getAuth(main),
        anon: getAuth(anon)
    }
};

getAuth(apps.main).useDeviceLanguage();
getAuth(apps.anon).useDeviceLanguage();